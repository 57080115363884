import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';  
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { AudioLoader } from 'three/src/loaders/AudioLoader.js';


const scrollmsg = document.getElementById('scrolling-message');
setTimeout(() => {
  scrollmsg.style.opacity = '0'; 
 scrollmsg.style.display = 'none'; 
}, 25000);

setTimeout(() => {
  scrollmsg.style.opacity = '5'; 
 }, 10000);

// Scene setup

let battleAxe; // Déclarez ici pour une portée globale
let loadedSounds = 0;
const totalSounds = 12;

  // GESTION INTRO-screen 
  const hasSeenIntro = localStorage.getItem('hasSeenIntro');
  console.log(hasSeenIntro);


const scene = new THREE.Scene();
const vertexShader = `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const fragmentShader = `
varying vec2 vUv;

void main() {
vec3 skyColor = mix(vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vUv.y);
  gl_FragColor = vec4(skyColor, 1.0);
}
`;

const skyMaterial = new THREE.ShaderMaterial({
  vertexShader,
  fragmentShader,
  side: THREE.BackSide // Pour s'assurer que le matériau est visible de l'intérieur
});

// SphereGeometry pour envelopper la scène
const skyGeometry = new THREE.SphereGeometry(100, 32, 32);
const sky = new THREE.Mesh(skyGeometry, skyMaterial);
scene.add(sky);



// Camera setup
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
// camera.position.z = 15;  // Set a good distance to see the planets clearly

// // Positionner la caméra pour une vue en plongée
// camera.position.set(-2, -3, 26); // Positionner la caméra plus haut sur l'axe Y
// camera.lookAt(0, 0, 100);  // Regarder vers le bas au centre de la scène (en y = 0)

// Renderer setup
const canvas = document.querySelector('#webgl');  // Using the canvas with class "webgl"
const renderer = new THREE.WebGLRenderer({ canvas }); // Set renderer to use the existing canvas
renderer.setSize(window.innerWidth, window.innerHeight);


// Orbit controls
const controls = new OrbitControls(camera, renderer.domElement);

// Limiter le zoom en définissant les distances minimales et maximales
controls.minDistance = 3; // Distance minimale (par exemple 5 unités)
controls.maxDistance = 35; // Distance maximale (par exemple 20 unités)

// ----------  // MOBILE SET UP PERF

if (window.innerWidth < 768) { // Détection mobile
  renderer.setPixelRatio(0.75); // Diminue la résolution
} else {
  renderer.setPixelRatio(window.devicePixelRatio);
}
controls.enableDamping = true;
controls.dampingFactor = 0.1; // Ajoutez un amortissement


// Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 0.7); // 0.5= Lumière douce
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
directionalLight.position.set(5, 10, 7.5); // Position de la lumière
scene.add(directionalLight);


const pointLight = new THREE.PointLight(0xffffff, 1);
pointLight.position.set(10, 10, 10);
scene.add(pointLight);

// Particles background
const particleGeometry = new THREE.BufferGeometry();
const particlesCount = 1200;
const positions = new Float32Array(particlesCount * 3);

for (let i = 0; i < particlesCount * 0.3; i++) {
  positions[i] = (Math.random() - 0.5) * 50;
}

particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
const particleMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 0.05 });
const particles = new THREE.Points(particleGeometry, particleMaterial);
scene.add(particles);

// Planets setup (arranged in a circle)
const planetGeometry = new THREE.SphereGeometry(1.2, 32, 32);  // Planets are now a bit smaller
const planetMaterial = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
const planets = [];
const textures = [
  '122798982881146.jpg',
  'PavingStones138_1K-JPG_Displacement.jpg',
  'antiyèz.jpg',
  'Marble006_Roughness.jpg',
  'Marble006_1K-JPG_Displacement.jpg',
  'NormalMap.jpg',
  'Asphalt025C_displacement.jpg',
  '4040282.jpg',
  '9290536.jpg',
  'palé ba yo.jpg',
  'flat-lay-purple-material.jpg',
  'pink-lime-plaster-with-cracks-background.jpg'
];

// Parameters for the circular layout
const radius = 10; // Distance of planets from the center of the scene
const numPlanets = 12; // Total number of planets
const angleStep = (Math.PI * 2) / numPlanets; // Angle step to place planets evenly

// Load the font once
const fontLoader = new FontLoader();
let font;

// Load the font file
fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (loadedFont) => {
  font = loadedFont;
  const ringRadii = [12, 14, 16]; 
  
  
function resetPlanetsPosition() {
  planets.forEach((planet, i) => {
    // Recalculate position on a ring
    const angleStep = (Math.PI * 2) / numPlanets;
    const radius = (i < 3) ? ringRadii[0] : (i < 8) ? ringRadii[1] : ringRadii[2];
    const angle = i * angleStep;
    const x = radius * Math.cos(angle);
    const z = radius * Math.sin(angle);
    const y = 0;
      // Reset the planet's position
      planet.position.set(x, y, z);
  });

}

document.getElementById('btn3').addEventListener('click', () => {
  resetPlanetsPosition();
  //alert ("bien réinitialisé");
});

document.getElementById('btn4').addEventListener('click', () => {
  cameraMoving =false;
});


   // Add galaxy rings
     function addGalaxyRings() {
      const ringMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff, // Color of the ring
          side: THREE.DoubleSide, // Ensure visibility from both sides
          transparent: true, // Allow transparency
          opacity: 0.5 // Slightly transparent for a subtle effect
      });
      
      // Define ring radii
     // Radii for the three rings
      const ringThickness = 0.03; // Thickness of each ring

      ringRadii.forEach(radius => {
          const ringGeometry = new THREE.RingGeometry(
              radius, // Inner radius
              radius + ringThickness, // Outer radius
              64 // Number of segments for smoothness
          );

          // Rotate the ring to lie flat on the horizontal plane
          ringGeometry.rotateX(Math.PI / 2);

          const ring = new THREE.Mesh(ringGeometry, ringMaterial);
          scene.add(ring); // Add the ring to the scene
      });
    }

addGalaxyRings();

  // Add planets n things to the scene and assign random colors
 // for (let i = 0; i < numPlanets; i++) {
    
    

  // Function to interpolate between two colors
function interpolateColor(startColor, endColor, factor) {

 const adjustedFactor = Math.pow(factor, 0.2); // Modifie le facteur pour rendre le rouge plus dominant
  
  const r = startColor.r + (endColor.r - startColor.r) * adjustedFactor;
  const g = startColor.g + (endColor.g - startColor.g) * adjustedFactor;
  const b = startColor.b + (endColor.b - startColor.b) * adjustedFactor;
  return new THREE.Color(r, g, b);
}
// Define the start and end colors (Green and Red)
const colorStart = new THREE.Color(0x2ead99 ); // Green
const colorEnd = new THREE.Color(0x2ead01);   // Red

for (let i = 0; i < numPlanets; i++) {
  const planet = new THREE.Mesh(planetGeometry, planetMaterial.clone());
  
  // Assign a color
  const colorFactor = i / (numPlanets - 1);
  const planetColor = interpolateColor(colorStart, colorEnd, colorFactor);
  planet.material.color.set(planetColor);

  // Calculate position on a ring
  const angleStep = (Math.PI * 2) / numPlanets;
  const radius = (i < 3) ? ringRadii[0] : (i < 8) ? ringRadii[1] : ringRadii[2];
  const angle = i * angleStep;
  const x = radius * Math.cos(angle);
  const z = radius * Math.sin(angle);
  const y = 0;

  planet.position.set(x, y, z);

  // Apply texture if available
  if (i < textures.length) {
    const texture = textureLoader.load(textures[i]);
    planet.material.map = texture;
  }

  planets.push(planet);
  scene.add(planet);

  // Add text for planet number
  const textGeometry = new TextGeometry(planetSongs[i].id, { font, size: 0.6, depth: 0.1 });
  const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
  const textMesh = new THREE.Mesh(textGeometry, textMaterial);
  textMesh.position.set(x, y + 1.5, z);
  textMesh.rotation.set(0, y + 1.5, 0);
    // Store textMesh in planet's userData
    planet.userData.textMesh = textMesh;
  scene.add(textMesh);

  // Add text for planet name
  const text2Geometry = new TextGeometry(planetSongs[i].name, { font, size: 0.3, depth: 0.1 });
  const text2Material = new THREE.MeshBasicMaterial({ color: 0xffffff });
  const text2Mesh = new THREE.Mesh(text2Geometry, text2Material);
  text2Mesh.position.set(x + 1, y - 1.5, z + 1);
  text2Mesh.rotation.set(0, y + 1.5, 0);
    // Store textMesh in planet's userData
    planet.userData.text2Mesh = text2Mesh;
  scene.add(text2Mesh);
}

  
// } // FIN IF FOR ECH PLNETS principl

});



// -----------------// IMPORT HaCHE  battleAxe // -----------------//

 
const fbxLoader = new FBXLoader();
const textureLoader = new THREE.TextureLoader();


fbxLoader.load(
  '/Battle_ax.fbx', // Remplacez par le chemin correct vers votre fichier FBX
  (object) => {
    battleAxe = object;
    battleAxe.scale.set(0.1, 0.1, 0.1); // Ajustez la taille si nécessaire
    battleAxe.position.set(0, 0, 0);    // Positionnez au centre du cercle

    // Chargez une texture (par exemple, pour la surface de la hache)
    const axeTexture = textureLoader.load('/Battle_ax_BaseColor_2k.png'); // Remplacez par le chemin correct
    const aoTexture = textureLoader.load('/Battle_ax_Ambient Occlusion_2k.jpg');
    const metalikTexture = textureLoader.load('/Battle_ax_Metalic_2k.png');

    if (battleAxe) {
      battleAxe.traverse((child) => {
        if (child.isMesh) {
          child.material.map = axeTexture; // Base color texture
          child.material.aoMap = aoTexture; // Ambient occlusion texture
          child.material.aoMap = metalikTexture; // Ambient occlusion texture
        }
      });
    }
    scene.add(battleAxe);
  },
  (xhr) => {
    console.log(`Battle_ax.fbx loading: ${(xhr.loaded / xhr.total) * 100}% loaded`);
  },
  (error) => {
    console.error('An error occurred while loading Battle_ax.fbx:', error);
  }
);

// -----------------// FIN IMPORT HaCHE  battleAxe // -----------------//



// Tracklist element
const tracklistDiv = document.getElementById('tracklist-items');

// Songs for each planet
const planetSongs = [
  
  { id: "1", name: "ADOUMANMAN feat M'rick", file: "planet1.mp3" },
  { id: "2", name: "KAFE KOLA", file: "planet2.mp3" },
  { id: "3",name: "ANTIYEZ", file: "planet3.wav" },
  { id: "4",name: "ON VI", file: "planet4.mp3" },
  { id: "5",name: "AYOWI", file: "planet5.mp3" },
  { id: "6",name: "MANDE", file: "planet6.mp3" },
  { id: "7",name: "Mousse eve on ash (interlude)", file: "planet7.wav" },
  { id: "8",name: "NOYA", file: "planet8.mp3" },
  { id: "9",name: "DISTANS", file: "planet9.mp3" },
  { id: "10",name: "WSPH (work smart play hard)", file: "planet11.wav" },
  { id: "11",name: "PALE BA YO", file: "planet10.mp3" },
  { id: "12",name: "   LIKID", file: "planet12.mp3" }
 
];


// ----------------//SET UP CaM INITIaL Trouver la position de la planète 1
const planetIndex = planetSongs.findIndex(song => song.id === "1");
const angle = planetIndex * angleStep; // Angle correspondant à la planète 1
const x = radius * Math.cos(angle);
const z = radius * Math.sin(angle);

camera.position.set(x+15, 3, z + 4); // Positionner la caméra au-dessus de la planète 1
camera.lookAt(0, 0, 100);

         
// ------------------PRELOD SONS retiré---------------//
 // fin Function preload one audio file


// Function to preload all audio files
 // FIN ------------------PRELOD SONS ---------------
// Call preloadAllAudio to start preloading files
//preloadAllAudio();
// ------------------FIN PRELOD SONS retiré---------------//

// Mettre à jour la progress bar
function updateProgressBar() {
  const progressBar = document.getElementById('audioProgress');
  progressBar.value = (loadedSounds / totalSounds) * 100;
}

// Récupère l'écran d'intro
const introScreen = document.getElementById('intro-screen');

// Initialise les variables pour charger les sons
const audioLoader = new AudioLoader();
const loadedSoundsKey = 'loadedSounds'; // Clé pour LocalStorage


// Récupère les sons déjà chargés depuis LocalStorage
let cachedSounds = [];
try {
  const storedSounds = JSON.parse(localStorage.getItem(loadedSoundsKey));
  if (Array.isArray(storedSounds)) {
    cachedSounds = storedSounds;
  }
} catch (e) {
  console.warn("Failed to parse cached sounds from LocalStorage:", e);
}

// Fonction pour vérifier si tous les sons sont chargés
function checkAllSoundsLoaded() {
  if (loadedSounds === totalSounds) {
    console.log("All sounds loaded.");
    setTimeout(() => {
      introScreen.style.opacity = '0'; // Transition en fondu
    
        setTimeout(() => {
        introScreen.style.display = 'none'; // Cache complètement après le fondu
    // Durée de l'animation
      }, 500); 

    }, 5000); 

    
    // Marquer l'intro comme vue dans LocalStorage
    localStorage.setItem('hasSeenIntro', 'true');
  }
}

// Charge chaque son et met à jour la barre de progression
planetSongs.forEach((song) => {
  // Si le son est déjà dans le cache, passe au suivant
  if (cachedSounds.includes(song.file)) {
    console.log(`Sound already loaded: ${song.name}`);
    loadedSounds++;
    updateProgressBar();
    checkAllSoundsLoaded();
    return;
  }else {

 // Charge le son
  audioLoader.load(
    `${song.file}`, // Chemin du fichier audio
    () => {
      loadedSounds++;
      console.log(`Loaded sound: ${song.name}`);

      // Ajoute le son au cache
      cachedSounds.push(song.file);
      localStorage.setItem(loadedSoundsKey, JSON.stringify(cachedSounds));

      // Met à jour la barre de progression
      updateProgressBar();

      // Vérifie si tous les sons sont chargés
      checkAllSoundsLoaded();
    },
    undefined,
    (error) => {
      console.error(`An error occurred while loading sound: ${song.file}`, error);
    }
  );
}
});


// Charger les paroles
let lyricsData = null;

fetch('/lyrics.json') // Charger le fichier JSON des paroles
  .then(response => response.json())
  .then(data => {
    lyricsData = data;
  })
  .catch(error => console.error('Erreur de chargement des paroles :', error));

  let currentLyricsProcess = null; // Variable pour suivre le processus en cours

  function displayLyrics(songFile) {
    const lyrics = lyricsData[songFile]; // Obtenir les paroles pour la chanson
    if (!lyrics) return;
  
    const lyricsContainer = document.getElementById('lyrics-container');
    lyricsContainer.innerHTML = ''; // Réinitialiser les paroles
    lyricsContainer.style.display = 'block';
    let index = 0; // Indice pour suivre les paroles affichées
    
    // Annuler tout processus en cours
    if (currentLyricsProcess) {
      cancelAnimationFrame(currentLyricsProcess);
      currentLyricsProcess = null;
    }
  
    // Fonction pour mettre à jour les paroles en fonction du temps écoulé
    const updateLyrics = () => {
      if (index < lyrics.length && audioPlayer.currentTime >= lyrics[index].time) {
        const lyricLine = document.createElement('p');
        lyricLine.textContent = lyrics[index].text;
        lyricsContainer.appendChild(lyricLine);
  
        // Faire défiler les anciennes lignes
        lyricsContainer.scrollTop = lyricsContainer.scrollHeight;
  
        index++; // Passer à la prochaine ligne de paroles
      }
  
      // Continuer à mettre à jour si des paroles restent à afficher
      if (index < lyrics.length && audioPlayer.currentTime < lyrics[lyrics.length - 1].time) {
        currentLyricsProcess = requestAnimationFrame(updateLyrics);
      }
    };
  
    // Lancer l'affichage des paroles
    updateLyrics();
  }


//------------------------ DEBUT Interaction setup ----------------------------//

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let currentAudio = null; // Track the current audio to avoid interruptions
const audioPlayer = document.getElementById('audioPlayer');  // Get the audio element
const audioSource = document.getElementById('audioSource'); // Get the audio source element

// Variables pour le déplacement de la caméra
let cameraTargetPosition = null; // Position cible pour le déplacement de la caméra
let cameraMoving = false; // Indique si la caméra est en déplacement
let targetPlanet = null; // Planète cible autour de laquelle la caméra tournera
let rotationSpeed = 0.03; // Vitesse de rotation autour de la planète cible

  

    // Fonction pour déclencher le mouvement de la caméra et mettre à jour les informations
    function focusOnPlanet(index) {
      // Définir la planète cible
      targetPlanet = planets[index];
      
      cameraTargetPosition = targetPlanet.position.clone().add(new THREE.Vector3(5, +4.3, 3)); // Ajuster l'offset pour le focus
     

      // Déclencher l'animation de la caméra
      cameraMoving = true;
    
      // Mettre à jour les informations de la planète
      planetInfoDiv.textContent = `Vous écoutez la planète ${index + 1}: ${planetTexts[index]}`;
      planetInfoDiv.style.display = "block";

      function animateMovement() {
        // Position actuelle de la planète
    let currentY = planets[index].position.y;
    let targetY = 3.6; // Nouvelle position de la planète
        // Calcul de l'étape de déplacement à chaque frame
        const step = (targetY - currentY) * 0.01; // Facteur de vitesse du mouvement
      
        // Mise à jour de la position actuelle de la planète
        currentY += step;
      
        // Appliquer la nouvelle position à la planète
        planets[index].position.y = currentY;
      
        // Vérifier si la planète a atteint la position cible
        if (Math.abs(currentY - targetY) > 0.1) { // Tolérance pour les flottements numériques
          requestAnimationFrame(animateMovement); // Répéter l'animation
        }
      }
      animateMovement();// Lire la chanson suivante
       
    }

// Ajouter les éléments de la liste de lecture dans le div
planetSongs.forEach((song, index) => {
  const li = document.createElement('li');
  li.textContent = `${song.id}. ${song.name}`;
  li.dataset.index = index; // Stocker l'index pour un accès facile
  li.style.cursor = "pointer";
  li.addEventListener('click', () => {
    focusOnPlanet(index);
    playPlanetSong(index); // Lire la chanson correspondante lors du clic
   
  });

  tracklistDiv.appendChild(li);
});


// Function to highlight the currently playing song
function highlightPlayingSong(index) {
  const trackItems = tracklistDiv.children;
  const canvasp = document.querySelector('canvas');
  for (let i = 0; i < trackItems.length; i++) {

    if (i === index) {
      trackItems[i].style.color = "orange"; // Highlight the playing song
      trackItems[i].style.fontWeight = "bold";
      trackItems[i].style.fontSize = "18px";
    } else {
      trackItems[i].style.color = "white"; // Reset others to default
      trackItems[i].style.fontWeight = "normal";
    }
  }
  
}

const canvasp = document.querySelector('canvas');
const tracklist = document.getElementById('tracklist');
// Disable pointer events on canvas when hovering over tracklist
tracklist.addEventListener('mouseenter', () => {
  canvasp.style.pointerEvents = 'none';
});

// Enable pointer events on canvas when not hovering over tracklist
tracklist.addEventListener('mouseleave', () => {
  canvasp.style.pointerEvents = 'auto';
});


let currentAudioIndex = -1; // unplay 1st song Index du son actuellement joué

function playPlanetSong(index) {
 // document.querySelector('.wave').style.display = 'block'; // Affiche la vague quand le son joue (div .wave supp)
  
  const song = planetSongs[index];
  if (!song) return;

  // Mettre à jour l'index actuel
  currentAudioIndex = index;
  const songFile = audioSource.src.split('/').pop();
  
  // Arrêter l'audio actuel s'il y en a
  if (currentAudio) {
    currentAudio.pause();
  }

    // Réinitialiser les paroles quand la chanson recommence (par exemple, si elle est redémarrée)
    audioPlayer.addEventListener('play', () => {  
      displayLyrics(songFile); // Recommence l'affichage des paroles
    });
    

  // Configurer la source audio pour la nouvelle chanson
  audioSource.src = song.file;
  audioPlayer.load();
  audioPlayer.play();
  currentAudio = audioPlayer;

  // Mettre en évidence la chanson actuelle dans la liste
  highlightPlayingSong(index);
  
  // Ajouter un gestionnaire pour écouter la fin du son et passer au suivant
  currentAudio.onended = () => {
    playNextSong();
  };
  
}

 
function playNextSong() {
  // Avancer à la chanson suivante
  const nextIndex = (currentAudioIndex + 1) % planetSongs.length; // Boucle au début
  playPlanetSong(nextIndex);
   
  function animateMovement() {
    // Position actuelle de la planète
    
    let currentY = planets[currentAudioIndex].position.y;
    let targetY = +6; // Nouvelle position de la planète
    // Calcul de l'étape de déplacement à chaque frame
    const step = (targetY - currentY) * 0.01; // Facteur de vitesse du mouvement
  
    // Mise à jour de la position actuelle de la planète
    currentY += step;
  
    // Appliquer la nouvelle position à la planète
    planets[currentAudioIndex].position.y = currentY;
    
    // Vérifier si la planète a atteint la position cible
    if (Math.abs(currentY - targetY) > 0.1) { // Tolérance pour les flottements numériques
      requestAnimationFrame(animateMovement); // Répéter l'animation
    }
   
    
  }animateMovement();

}

// Lecture initiale si nécessaire
playPlanetSong(currentAudioIndex);

// Click event listener
window.addEventListener('click', (event) => {
  // Update mouse coordinates in normalized device coordinates (NDC)
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // Set raycaster based on the mouse position
  raycaster.setFromCamera(mouse, camera);

  // Check intersections with planets
  const intersects = raycaster.intersectObjects(planets);

  if (intersects.length > 0) {
    const planet = intersects[0].object;
    const planetIndex = planets.indexOf(planet);
    
    if (planetIndex >= 0) {
      playPlanetSong(planetIndex);
    }
  
// Position actuelle de la planète
let currentY = planets[planetIndex].position.y;
let targetY = 5; // Nouvelle position de la planète

// Fonction d'animation
function animateMovement() {
  // Calcul de l'étape de déplacement à chaque frame
  const step = (targetY - currentY) * 0.01; // Facteur de vitesse du mouvement

  // Mise à jour de la position actuelle de la planète
  currentY += step;

  // Appliquer la nouvelle position à la planète
  planets[planetIndex].position.y = currentY;

  // Vérifier si la planète a atteint la position cible
  if (Math.abs(currentY - targetY) > 0.1) { // Tolérance pour les flottements numériques
    requestAnimationFrame(animateMovement); // Répéter l'animation
  }
}

    // Set the new audio source based on the clicked planet
    audioSource.src = planetSongs[planetIndex].file;  // Update the audio source
    audioPlayer.load();  // Reload the audio element to apply the new source
    audioPlayer.play();  // Play the new audio

    currentAudio = audioPlayer; // Update the current audio to the new one

    // ------------MOOVE PLaNETE ON CLICK ON IT --------//
    animateMovement();

    // Set the target position slightly offset from the planet
    cameraTargetPosition = new THREE.Vector3(
      planet.position.x ,
      planet.position.y + 1.3,
      planet.position.z - 0.5 // Position slightly in front of the planet
    );
    targetPlanet = planet; // Set the target planet for rotation
    cameraMoving = true; // Start moving the camera
    // Lancer le mouvement de la planète

  }
});

// Get the HTML div element
const planetInfoDiv = document.getElementById("planet-info");
// Specific alert for each planet
const planetTexts = [
    "  1. L'univers depuis Adoumanman, feat M'rick.",
  "  2. L'univers depuis Kafé Kola.",
  "  3. L'univers depuis Antiyèz.",
  "  4. L'univers depuis On vi.",
  "  5. L'univers depuis Ayowi.",
  "  6. L'univers depuis Mandé.",
  "  7. L'univers depuis l'interlude.",
  "  8. L'univers depuis Noya.",
  "  9. L'univers depuis Distans.",
  "  10. L'univers depuis WSPH (work smart play hard).",
  "  11. L'univers depuis Palé ba yo.",
  "  12. L'univers depuis Likid.",  
];

// let isMouseActive = false; // Drapeau pour vérifier si la souris est active
// let mouseTimeout = null; // Permet de remettre le drapeau à `false` après un délai

// Détecter les événements de souris
// window.addEventListener('mousemove', () => handleMouseActivity());
// window.addEventListener('mousedown', () => handleMouseActivity());

// function handleMouseActivity() {
//   isMouseActive = true;
//   cameraMoving = false;
//   // Réinitialiser le drapeau après un délai d'inactivité
//   clearTimeout(mouseTimeout);
//   mouseTimeout = setTimeout(() => {
//     isMouseActive = false;
//   }, 8000); // 1 seconde d'inactivité pour réactiver la rotation
// }



      // ----------------------------aNIMaTION -------------------//

      
function animate() {
  requestAnimationFrame(animate);
  // Handle camera movement

  planets.forEach(planet => {
    planet.rotation.y += 0.001;

  });

  function handleCameraMovement() {
    if (cameraMoving && cameraTargetPosition) {
      
      
    setTimeout(() => {
      // Rotate planets around the circle and update text positions
      const time = performance.now() * 0.0001; // Adjust speed by changing the multiplier
      planets.forEach((planet, i, a) => {
        const angle = i * angleStep + time; // Increment angle with time
        const x = radius * Math.cos(angle);
        const z = radius * Math.sin(angle);
       
      //  planet.position.set(x, 0, z); // Update position on the circle
  
        // Update associated text position
        const textMesh = planet.userData.textMesh; // Assuming textMesh is stored in userData
        if (textMesh) {
          textMesh.rotation.y +=0.005;
        //  textMesh.position.set(x, 1.5, z); // Slightly above the planet
        }
  
         // Update associated text position
         const text2Mesh = planet.userData.text2Mesh; // Assuming textMesh is stored in userData
         if (text2Mesh) {
           
                      // Tableau des valeurs possibles pour la rotation
              const rotationValues = [0.0005, 0.0002, 0.0008];
  
              // Fonction pour appliquer une rotation aléatoire
              function applyRandomRotation() {
                // Sélectionner une valeur aléatoire parmi le tableau
                const randomValue = rotationValues[Math.floor(Math.random() * rotationValues.length)];
                
                // Appliquer la valeur aléatoire à la rotation
                text2Mesh.rotation.y -= randomValue;
              }
              applyRandomRotation();
         //  text2Mesh.position.set(x, -2, z); // Slightly above the planet
         }
      }); }, 100); 

      const currentPosition = camera.position.clone();
      const direction = cameraTargetPosition.clone().sub(currentPosition).normalize();
      const distance = currentPosition.distanceTo(cameraTargetPosition);
  
      const maxSpeed = 0.028;
      const minSpeed = 0.005;
  
      const movementSpeed = Math.max(minSpeed, Math.min(maxSpeed, distance * 0.04));  
      if (distance > 0.1) {
        
        camera.position.add(direction.multiplyScalar(movementSpeed));
       
  
        // Update the info text if a target is set
        if (targetPlanet) {
          const planetIndex = planets.indexOf(targetPlanet);
          planetInfoDiv.textContent = planetTexts[planetIndex]; // Update the text
          planetInfoDiv.style.display = "block"; // Show the div
      
        }
        
        
      } else {
  
        cameraMoving = true;
        setTimeout(() => {
        planetInfoDiv.textContent = "Bonne écoute !";
              }, 3000);
       
      }
    }
  }   handleCameraMovement();


      //  // Vérifiez si battleAxe est défini avant d'appliquer la rotation
      if (battleAxe) {
        battleAxe.rotation.y -= 0.01; // Rotation hache
        }
      
  
  // Rotate planets on their axes (only if the mouse is not active)
  // if (!isMouseActive & isMouseActive) {
  
  // }


  controls.update(); // Update OrbitControls
  renderer.render(scene, camera); // Render the scene


  // GESTION BOUTON TRaklist
document.addEventListener("DOMContentLoaded", () => {
  const toggleButton = document.getElementById("toggleTracklistButton");
  const tracklistItems = document.getElementById("tracklist-items");

  toggleButton.addEventListener("click", () => {
    // Basculer la visibilité de la liste
    if (tracklistItems.style.display === "none" || tracklistItems.style.display === "") {
     
      tracklistItems.style.display = "block";
      toggleButton.style.backgroundColor="#7d247dcc";
      toggleButton.textContent = "Masquer Tracklist";
    } else {
      tracklistItems.style.display = "none";
      toggleButton.textContent = "Afficher Tracklist";
    }
  });
});

// Hide the info div when clicking elsewhere
window.addEventListener('click', (event) => {
  if (!cameraMoving && targetPlanet) {
    const clickedElement = event.target;
    if (!clickedElement.closest("#planet-info")) {
      planetInfoDiv.style.display = "none"; // Hide the div
    }
  }
});


 }

animate();

// // Responsive design 
window.addEventListener('resize', () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});



  

